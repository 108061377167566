const state = () => ({
    languages: ["English", "Latvian"], // Initial list of languages
    selectedLanguage: localStorage.getItem("selectedLanguage")
        ? JSON.parse(localStorage.getItem("selectedLanguage"))
        : "Latvian", // Default language persisted across reloads
});

const mutations = {
    SET_LANGUAGES(state, languages) {
        state.languages = languages;
    },
    SET_SELECTED_LANGUAGE(state, language) {
        state.selectedLanguage = language;
        localStorage.setItem("selectedLanguage", JSON.stringify(language)); // Persist selected language to localStorage
    },
};

const actions = {
    initializeLanguages({ commit }) {
        // This action can be extended to fetch languages from an API if needed
        commit("SET_LANGUAGES", ["English", "Latviešu"]);
        if (!localStorage.getItem("selectedLanguage")) {
            commit("SET_SELECTED_LANGUAGE", "Latviešu");
        }
    },
    updateSelectedLanguage({ commit }, language) {
        commit("SET_SELECTED_LANGUAGE", language);
    },
};

const getters = {
    getLanguages: (state) => state.languages,
    getSelectedLanguage: (state) => state.selectedLanguage,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
