// messages.js
export default {
    room_schedule: "Stundu saraksts",
    teachersAndStudents: "Skolotāji un skolēni",
    individual_plan: "Individuālais plāns",
    monday: "Pirmdiena",
    thuesday: "Otrdiena",
    wednesday: "Trešdiena",
    thursday: "Ceturtdiena",
    friday: "Piektdiena",
    saturday: "Sestdiena",
    sunday: "Svētdiena",
    search: "Meklēt",
    room: "Telpa",
    rooms: "Telpas",
    students: "Skolnieki",
    status: "Statuss",
    teachers: "Skolotājs",
    name: "Nosaukums",
    event: "Notikums",
    eventName: "Notikuma nosaukums",
    category: "Kategorija",
    select: "Izvēlēties",
    location: "Telpa",
    period: "Periodi",
    single: "Vienreizējs",
    recurring: "Ar atkārtojumu",
    date: "Datums",
    from: "No",
    until: "Līdz",
    to: "To",
    create: "Izveidot",
    edit: "Labot",
    cancel: "Atcelt",
    save: "Saglabāt",
    delete: "Dzēst",
    time: "Laiks",
    ohSorry: "Atvainojamies, ir kļūda....",
    goBackToUpdate: "Atgriezties, lai labotu",
    fieldIsRequired: "Lauks ir jāaizpilda",
    fieldIsNotValid: "Lauks nav derīgs",
    january: "Janvāris",
    february: "Februāris",
    march: "Marts",
    april: "Aprīls",
    may: "Maijs",
    june: "Jūnijs",
    jule: "Jūlijs",
    august: "Augusts",
    september: "Septembris",
    october: "Oktobris",
    november: "Novembris",
    december: "Decembris",
    weekly: "iknedēļas",
    everySecondWeek: "Katru otro nedēļu",
    everyThirdWeek: "Katru trešo nedēļu",
    monthly: "Katru mēnesi",
    add: "Pievienot",
    saveAll: "Saglabātu visu",
    saveClose: "Saglabāt un aizvērt ",
    dataCanNotBeSaved: "Dati netiks saglabāti. Vai vēlaties turpināt?",
    doYouWantToChangeAListRecurrence:
        "Vai vēlaties mainīt VISUS notikumus ar atkārtojumu?",
    doYouReallyWantToDeleteEvents: "Vai tiešām vēlaties dzēst notikumus?",
    inRoom: "telpā",
    lessonShouldEndBefore9Pm: "Mācību stundai jābeidzas pirms plkst. 21:00.",
    roomReservationError: "Telpa ir aizņemta šajā datumā.",
    studentReservationError:
        "Students/i ir aizņemti, lūdzu izvēlieties citu laiku.",
    studentReservationNoStudentsInGroupError: "Atlasītajās grupās nav skolēnu",
    teacherReservationError:
        "Skolotājs/i ir aizņemti, lūdzu, izvēlieties citu laiku.",
    subjectRequiredError: "Priekšmeta lauks ir obligāts.",
    dateFrom: "Datums no",
    dateUntil: "Datums līdz",
    timeFrom: "Laiks no",
    timeTo: "Laiks līdz",
    description: "Apraksts",
    eventDescription: "Notikuma apraksts",
    minTime: "Laikam vajadzētu būt pēc",
    maxTime: "Laikam vajadzētu būt iepriekš",
    // new words
    signIn: "Pieslēdzieties VMV",
    signUp: "Pieteikties",
    signOut: "Iziet",
    submit: "Iesniegt",
    email: "Epasts",
    password: "Parole",
    newPassword: "Jauna parole",
    resetPassword: "Jaunas paroles uzstādīšana",
    forgotPassword: "Aizmirsāt paroli?",
    emailError: "Epasta adrese nav pareiza!",
    passwordError: "6-10 zīmes",
    login: "Ienākt",
    confirmPassword: "Apstipriniet paroli",
    passwordNotMatch: "Paroles nesakrīt",
    surname: "Uzvārds",
    loginSuccess: "Pieslēgšanās ir veiksmīga.",
    loginFail: "Neizdevās pieslēgties, lūdzu, mēģiniet vēlreiz!",
    registerSuccess: "Reģistrācija ir veiksmīga.",
    emailWasSentSuccessful: "Epasts ir nosūtīts, lūdzu, pārbaudiet.",
    passwordResetSuccessful: "Parole ir veiksmīgi atjaunota.",
    signInByEclass: "Ienākt ar E-klasi",
    reservations: "Rezervācijas",
    selectClass: "Atlasīt klases laika posmu",
    subject: "Priekšmets",
    noRoomsSelected: "Sveicināti telpu rezervācijas sistēmā! <br> Lūdzu, izvēlieties telpu.",
};
