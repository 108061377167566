import "./bootstrap";
// import app from "./layouts/app.vue";
// import vuetify from "./vuetify";
// import router from './router'
//
// let Vue_app = createApp(app);
// vue_app.use(vuetify);
// vue_app.use(router)
// vue_app.mount("#app");
// Vue.config.globalProperties.$events = events;
// window.app = new Vue({
//     router,
//     store,
//     i18n,
//     vuetify,
//     render: h => h(App),
// }).$mount('#app');
// alert('test');

import { createApp } from "vue";

import Notifications from "@kyvg/vue3-notification";
import VCalendar from "v-calendar";
import "v-calendar/style.css";
import { createI18n } from "vue-i18n";
import api from "./api";
import store from "./store";
import router from "./router";
import vuetify from "./vuetify";
import globalMixin from "./mixins/global";
// import { Ripple } from 'vuetify/directives';
import App from "./App.vue";
import messages from "./lang";

const selectedLanguage = store.getters["language/getSelectedLanguage"] || "en";
const i18n = createI18n({
    // locale: store.getters['global/getLang'],
    locale:
        selectedLanguage === "English" || selectedLanguage === "en"
            ? "en"
            : "lv",
    fallbackLocale: "en",
    messages: messages,
});
import moment from "moment";
// import { DragonDropVue } from 'dragon-drop-vue'
// const dragonDropOptions = {
//     dragOverDebounceMs: 300,
//     debugLog: true,
// }

const Vue = createApp(App);
moment.locale(
    selectedLanguage === "English" || selectedLanguage === "en" ? "en" : "lv"
);

Vue.config.productionTip = false;
Vue.config.globalProperties.$moment = moment;
Vue.config.globalProperties.$api = api;

Vue.use(Notifications);
Vue.use(VCalendar, {
    componentPrefix: "vc",
});
// Vue.use(vuetify, {
//     directives: {
//         Ripple,
//     },
// });
Vue.mixin(globalMixin);
// Vue.use(Notifications);
Vue.use(router);
Vue.use(store);
Vue.use(i18n);
Vue.use(vuetify);
// Vue.use(DragonDropVue, dragonDropOptions);

store.watch(
    (state, getters) => getters["language/getSelectedLanguage"],
    (newLanguage) => {
        const newLocale =
            newLanguage === "English" || newLanguage === "en" ? "en" : "lv";
        i18n.global.locale = newLocale; // Dynamically update i18n locale
        moment.locale(newLocale); // Optionally update moment.js locale if needed

        // Update Vuetify locale
        vuetify.locale.current.value = newLocale;
    }
);

window.app = Vue.mount("#app");

// console.log('--------------------------------');
// console.log(window.app.$t('message.room'));
// console.log('--------------------------------');
