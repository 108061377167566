<template>
    <header class="header">
        <div class="logo">
            <svg width="101" height="41" viewBox="0 0 101 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                <!-- eslint-disable-next-line -->
                <path d="M63.339 0L50.5244 27.696L37.661 0L32.5254 2.62123L50.5244 41L68.4745 2.62123L63.339 0Z"
                    fill="#FF6B00" />
                <path d="M68.7191 16.5187H66.7627V24.6791H68.7191V16.5187Z" fill="#FF6B00" />
                <path d="M101 16.5187H99.0435V24.6791H101V16.5187Z" fill="#FF6B00" />
                <path d="M74.0992 10.2376H72.1428V30.9602H74.0992V10.2376Z" fill="#FF6B00" />
                <path d="M95.6197 10.2376H93.6633V30.9602H95.6197V10.2376Z" fill="#FF6B00" />
                <path d="M84.8595 12.2159H82.9031V28.9819H84.8595V12.2159Z" fill="#FF6B00" />
                <path d="M90.2396 3.75873H88.2832V37.4391H90.2396V3.75873Z" fill="#FF6B00" />
                <path d="M79.4794 1.53317H77.5229V39.6152H79.4794V1.53317Z" fill="#FF6B00" />
                <path d="M34.2372 16.5187H32.2808V24.6791H34.2372V16.5187Z" fill="#FF6B00" />
                <path d="M1.95642 16.5187H0V24.6791H1.95642V16.5187Z" fill="#FF6B00" />
                <path d="M28.857 10.2376H26.9006V30.9602H28.857V10.2376Z" fill="#FF6B00" />
                <path d="M7.33654 10.2376H5.38013V30.9602H7.33654V10.2376Z" fill="#FF6B00" />
                <path d="M18.0968 12.2159H16.1404V28.9819H18.0968V12.2159Z" fill="#FF6B00" />
                <path d="M12.7167 3.75873H10.7603V37.4391H12.7167V3.75873Z" fill="#FF6B00" />
                <path d="M23.4769 1.53317H21.5205V39.6152H23.4769V1.53317Z" fill="#FF6B00" />
            </svg>
        </div>
        <div class="navigation">
            <router-link to="/" class="navigation-link" exact>
                {{ $t("message.room_schedule") }}
            </router-link>
            <router-link v-if="
                _$can('rs', 'individual_plan') ||
                _$can('r', 'individual_plan')
            " to="/individual-plan" class="navigation-link">
                {{ $t("message.individual_plan") }}
            </router-link>
        </div>

        <div class="d-flex align-center ">
            <span class="mr-4">{{
                userData?.full_name
                }}</span>
            <span class="language">
                <SelectLanguage />
            </span>

            <ButtonError @click="signOut" :props="{
                disabled: disableLogout,
            }">{{ $t("message.signOut") }}</ButtonError>
        </div>
    </header>
</template>

<script>
import ButtonError from "@components/Shared/Buttons/ButtonError.vue";
import { mapActions, mapGetters } from "vuex";
import SelectLanguage from "../Shared/SelectLanguage.vue";
export default {
    components: {
        ButtonError,
        SelectLanguage
    },
    data: () => ({
        disableLogout: false,
        userData: null
    }),
    methods: {

        signOut() {
            this.disableLogout = true;
            this.$store.dispatch("auth/logout").finally(() => {
                this.$router.go("/sign-in");
            });
        },
    },
    computed: {
        ...mapGetters("auth", ["getUser"]),

    },
    watch: {
        userData: {
            handleError(newUserData) {
                console.log(15, newUserData)
            },
            immediate: true
        }
    },
    mounted() {
        console.log(151, this.$store.getters["auth/getUser"])
        this.userData = this.$store.getters["auth/getUser"]?.data

        console.log(151, this.userData)
    }


};
</script>

<style lang="scss" scoped>
.button_error {
    text-transform: inherit;
    height: 40px;
    padding: 0 19px;
    background-color: #f66a6a !important;
}

.bg-error {
    background-color: #f66a6a !important;
}

.header {
    background-color: #fff;
    height: 88px;
    min-height: 88px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: $defaultBorder;
    padding: 0 35px;


}



.navigation {
    .navigation-link {
        color: $defaultColor;
        display: inline-block;
        padding: 0 12px;
        text-decoration: none;
        font-weight: 500;
        position: relative;

        &.router-link-active {
            color: $accentColor;

            &:after {
                content: "";
                display: block;
                width: 42px;
                height: 2px;
                background-color: $accentColor;
                position: absolute;
                left: 12px;
                bottom: -5px;
            }
        }
    }
}

.language {
    margin-top: 28px;
}
</style>
